import { useMemo } from 'react'
import { calculateRangeForProducts, calculateTotalMonthsForProducts } from '../../utility/calculateTotalMonths'

// const calculateTotalForRow = (row, months) => parseFloat(row.price || 0) * parseFloat(row.quantity || 1) * months

// const calculateMediaSpend = (price, marginPercentage) => {
//   const marginValue = parseFloat(price) * (parseFloat(marginPercentage) / 100)
//   return (parseFloat(price) - marginValue).toFixed(2)
// }
const calculateTotalForRow = (row, months) => parseFloat(row.price || 0) * parseFloat(row.quantity || 1) * months

const calculateMediaSpend = (price, marginPercentage) => {
  const marginValue = parseFloat(price) * (parseFloat(marginPercentage) / 100)
  return (parseFloat(price) - marginValue).toFixed(2)
}
const csaTypes = ['CSA', 'RCSA']
const useTotals = (productRows, initialImplementationFee, type) => {
  const totals = useMemo(() => {
    let contractTotal = 0
    let grossProfit = 0
    let monthlyTotal = 0
    let ioTotal = 0
    let ioMonthlyTotal = 0
    // Get products with their respective total months
    const productsWithMonths = productRows ? calculateRangeForProducts(productRows) : []

    const { totalMonths } = calculateTotalMonthsForProducts(productsWithMonths)
    productsWithMonths.forEach(row => {
      const totalForRow = type === 'IO' ? calculateTotalForRow(row, row.months) : calculateTotalForRow(row, 1)
      const contractTotalRows = calculateTotalForRow(row, row.months)
      const mediaSpend = calculateMediaSpend(row.price, row.margin)

      // Accumulate gross profit and monthly total
      grossProfit += parseFloat(row.price) - parseFloat(mediaSpend)
      monthlyTotal += totalForRow // Monthly total is now per product
      contractTotal += contractTotalRows
    })

    // For IO-type deals, we accumulate the total from each product based on its individual months
    if (type === 'IO') {
      ioTotal = contractTotal
      ioMonthlyTotal = totalMonths ? ioTotal / totalMonths : 0
    }

    // Return totals based on the deal type
    return {
      grossProfit,
      implementationFee: parseFloat(initialImplementationFee),
      monthlyTotal: type === 'IO' ? ioMonthlyTotal : monthlyTotal,
      total: type === 'IO' ? ioTotal : csaTypes.includes(type) ? monthlyTotal : contractTotal, // Use ioTotal for IO deals, monthlyTotal for others
    }
  }, [productRows, initialImplementationFee, type])

  return totals
}

export default useTotals
