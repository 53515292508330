import React from 'react'
import { Table, TableBody, Typography, Box, useTheme } from '@mui/material'
import { EmptyRow } from './style'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import RenderRow from '../ContractDialog/RenderRow'

const ProductRowsTable = ({ newDeal, productRows, onDragEnd, onProductChange, validationErrors, removeRow }) => {
  const theme = useTheme()
  return (
    <Table
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? 'rgb(22, 28, 42, 0.3)' : 'rgba(255,255,255,1)',
      }}
    >
      {productRows && Array.isArray(productRows) && productRows.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {provided => (
              <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                {productRows.map((row, index) => (
                  <Draggable key={row.rowId} draggableId={row.rowId} index={index}>
                    {(provided, snapshot) => (
                      <RenderRow
                        key={row.rowId}
                        row={row}
                        index={index}
                        handleProductChange={onProductChange}
                        provided={provided}
                        snapshot={snapshot}
                        handleRemoveRow={removeRow}
                        validationErrors={validationErrors}
                        type={newDeal ? newDeal.type : ''}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            minHeight: '81px', // Ensure a minimum height for the row
          }}
        >
          <EmptyRow>
            <Typography sx={{ color: 'rgb(123, 126, 134)' }} variant='body1' align='center'>
              NO PRODUCTS, PLEASE ADD SERVICE
            </Typography>
          </EmptyRow>
        </Box>
      )}
    </Table>
  )
}

export default ProductRowsTable
