import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSnackbarContext } from '../SnackbarProvider/SnackbarProvider'
import { useUserProvider } from '../UsersProvider/UsersProvider'
import { useQueryClient } from '@tanstack/react-query'
import { createDealWonPayload } from '../../components/Notifications/createDealWonPayload'
import { useNotifyMessage } from '../../api/slack/useNotifyMessage'
import { useActiveUser } from '../../api/slack/useActiveUser'
import DetailsLoading from '../../components/Loading/DetailsLoading'
const WebsocketContext = createContext()

export const WebsocketProvider = ({ children }) => {
  const { showSnackbar } = useSnackbarContext()
  const { mutate: sendNotification } = useNotifyMessage()
  const [ws, setWs] = useState(null)
  const [customMessage, setCustomMessage] = useState('')
  const [connectedUsers, setConnectedUsers] = useState([])
  const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL || 'grobotic.app:2096/ws'
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const { user } = activeUser ? activeUser : {}
  const userId = user ? user.id : ''
  const userName = user ? user.real_name : ''
  const queryClient = useQueryClient()

  useEffect(() => {
    if (userId && userName) {
      console.log(`Websocket User: \nname: ${userName}, id: ${userId}`)
    }
  }, [userId, userName])

  useEffect(() => {
    if (isActiveUserLoading) {
      return // This is fine, simply exit early
    }
    const websocket = new WebSocket(`wss://${websocketUrl}?userId=${userId}&userName=${encodeURIComponent(userName)}`)

    websocket.onopen = () => {
      console.log('Connected to WebSocket server')
      setWs(websocket)
    }
    if (isActiveUserLoading) {
      return <DetailsLoading />
    }
    websocket.onmessage = event => {
      try {
        const data = JSON.parse(event.data)

        if (data.type === 'CONNECTED_USERS') {
          // Update the list of connected users
          const totalUsers = data && Array.isArray(data.users) ? data.users.length : 0
          console.log('Received CONNECTED_USERS:', totalUsers)
          setConnectedUsers(data.users)
          if (data && Array.isArray(data.users)) {
            return data.users.map(user => {
              console.log(`name: ${user.name} id: ${user.id}`)
            })
          }
        } else if (data.type === 'DEAL_WON') {
          console.log('Deal won event received:', data)

          // Trigger Slack notification for the deal won
          try {
            if (data.shouldNotify) {
              // Trigger Slack notification for the deal won
              const payload = createDealWonPayload(data.deal)
              sendNotification(payload, {
                onSuccess: () => {
                  showSnackbar(`Deal won! ${data.deal.name}`, 'broadcast')
                  console.log(`Successfully broadcasted Deal Won: ${data.deal.name}`)
                },
                onError: error => {
                  console.log(`Error creating task or sending thread: ${error}`)
                },
              })

              // Prevent other clients from sending notifications
              data.shouldNotify = false
            }
          } catch (e) {
            console.log('Error sending notifications for deal won')
          }
        } else if (data.type === 'UPDATE') {
          console.log('[Websocket Provider] UPDATE Triggered: ', data)
          queryClient
            .invalidateQueries([`/aws/${data.tableName}`, data.itemId])
            .then(result => {
              console.log('Query invalidated successfully:', `[/aws/${data.tableName}, ${data.itemId}]`)
            })
            .catch(error => {
              console.error('Error invalidating query:', error)
            })
        } else if (data.type === 'POST') {
          console.log('[Websocket Provider] POST Triggered: ', data)
          console.log('Invalidating...', `/aws/${data.tableName}`, data.itemId)
          queryClient.invalidateQueries([`/aws/${data.tableName}`, data.itemId])
        } else if (data.type === 'SAVE') {
          console.log('[Websocket Provider] SAVE Triggered: ', data)
          console.log('Invalidating...', `/aws/${data.tableName}`, data.itemId)
          queryClient.invalidateQueries([`/aws/${data.tableName}`, data.itemId])
        } else if (data.type === 'DELETE') {
          console.log('[Websocket Provider] DEALS DELETE Triggered: ', data)
          // Invalidate the deleted deal
          console.log('Invalidating...', `/aws/${data.tableName}`, data.itemId)
          queryClient.invalidateQueries([`/aws/${data.tableName}`, data.itemId])
          // Invalidate the affected organizations
          if (data.affectedOrganizationIds && data.affectedOrganizationIds.length > 0) {
            console.log('Invalidating organizations....', data.affectedOrganizationIds)
            data.affectedOrganizationIds.forEach(orgId => {
              console.log('Invalidating Organization with Id...', orgId)
              queryClient.invalidateQueries(['organizations', orgId])
            })
          }
        } else if (data.message) {
          const severity = data.severity === 'broadcast' ? 'broadcast' : 'grobotic'
          // console.log('SEVERITY: ', severity)
          // console.log('Snackbar Triggered with severity:', severity, data) // Log the determined severity
          showSnackbar(data.message, severity)
        } else if (data.customBroadcast) {
        } else {
          console.log('Other Websocket Event: ', data)
        }
      } catch (e) {
        console.error('Error parsing WebSocket message:', e)
      }
    }

    websocket.onclose = () => {
      console.log('WebSocket connection closed')
    }

    websocket.onerror = error => {
      console.error('WebSocket error:', error)
    }

    return () => {
      websocket.close()
    }
  }, [showSnackbar, websocketUrl, userId, userName, queryClient, isActiveUserLoading])

  const broadcastAnnouncement = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ message: customMessage, severity: 'broadcast' })) // Use `type: 'broadcast'`
    }
  }

  const websocketState = {
    customMessage,
    setCustomMessage,
    broadcastAnnouncement,
    connectedUsers,
  }

  return <WebsocketContext.Provider value={websocketState}>{children}</WebsocketContext.Provider>
}

export const useWebsocketContext = () => useContext(WebsocketContext)
