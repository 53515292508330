import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'
import BellIconWithCounter from '../Icons/BellIconWithCounter'
import NotificationDrawer from './NotificationDrawer'
import { useFetchNotifications } from '../../api/aws/useFetchNotifications'
import DetailsLoading from '../Loading/DetailsLoading'

export default function ShowNotifications({ drawerOpen, setDrawerOpen, imageUrl }) {
  const { notifications, isNotificationsLoading, isNotificationsError } = useFetchNotifications()
  const [selectedData, setSelectedData] = useState(null)

  const showDrawer = data => {
    setSelectedData(data)
    setDrawerOpen(true)
  }
  const closeDrawer = () => {
    setDrawerOpen(false)
    setSelectedData(null)
  }

  const drawerWidth = '75%'

  const handleClick = event => {
    event.stopPropagation()
    showDrawer(notifications)
  }
  if (isNotificationsLoading) {
    return <DetailsLoading />
  }

  return (
    <Box>
      <BellIconWithCounter showDrawer={showDrawer} handleClick={handleClick} imageUrl={imageUrl} data={notifications} />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <NotificationDrawer
          drawerOpen={drawerOpen}
          showDrawer={showDrawer}
          closeDrawer={closeDrawer}
          drawerWidth={drawerWidth}
          notifications={notifications}
        />
      </Box>
    </Box>
  )
}
