import React from 'react'
import { editFieldConfig } from './editFieldConfig'
import { Typography, TextField, Grid, Box, useTheme, Button } from '@mui/material'

const RenderStrategyHeader = ({ isEditMode, setIsEditMode, formState, onChange, onBlur }) => {
  const theme = useTheme()
  // const pageNames = ['General Information', 'Integration']
  const pageNames = ['General Information']

  return (
    <Box>
      <Grid container>
        <Grid item xs={10}>
          <Typography
            sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)', ml: 1 }}
            variant='h8'
          >
            General Info / Integration
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {isEditMode ? (
            <Button onClick={() => setIsEditMode(false)}>View Mode</Button>
          ) : (
            <Button onClick={() => setIsEditMode(true)}>Edit Mode</Button>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        p={0.5}
        sx={{
          backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgb(255, 255, 255)',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        {Object.keys(editFieldConfig).map((pageName, pageIndex) => (
          <React.Fragment key={pageIndex}>
            {pageNames.includes(pageName) ? (
              <>
                {editFieldConfig[pageName].map((field, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, mb: 0.5 }}>
                      <Typography
                        sx={{
                          width: '40%',
                          fontWeight: 'bold',
                          ml: 2,
                        }}
                      >
                        {field.label}
                      </Typography>

                      {!isEditMode ? (
                        <Typography
                          sx={{
                            flexGrow: 1,
                            padding: '8px 16px',
                            backgroundColor: theme.palette.mode === 'dark' ? 'rgb(44, 51, 61)' : 'rgb(245, 245, 245)',
                            borderRadius: '4px',
                            fontSize: '0.875rem',
                            color: theme.palette.mode === 'dark' ? 'rgb(181, 191, 205)' : 'rgb(97, 97, 97)',
                          }}
                        >
                          {formState[field.name] || '—'}
                        </Typography>
                      ) : (
                        <TextField
                          name={field.name}
                          type={field.type || 'text'}
                          value={formState[field.name] || ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          variant='outlined'
                          fullWidth
                          disabled={field.readOnly}
                          size='small'
                          InputProps={{
                            readOnly: field.readOnly,
                          }}
                          sx={{
                            flexGrow: 1,
                            backgroundColor: theme.palette.mode === 'dark' ? 'rgb(44, 51, 61)' : 'rgb(255, 255, 255)',
                            borderRadius: '4px',
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                ))}
              </>
            ) : null}
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  )
}

export default RenderStrategyHeader
