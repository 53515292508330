/* You can add a script to your package.json to automate this process:
"scripts": {
  "version": "standard-version && node generatePatchNotes.js",
  "postversion": "git push --follow-tags origin master && git push origin master",
  // other scripts...
}
*/

import React, { useState } from 'react'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import { Box, List, ListItem, IconButton, Modal, Typography, Tooltip, useTheme, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import packageJson from '../../package.json'
import { patchNotes } from '../patchNotes'
import { BoxScroller } from '../style/styleElements'

export const version = packageJson.version

const GroboticVersionControl = () => {
  const [notesOpen, setNotesOpen] = useState(false)
  const theme = useTheme()
  const versionTextColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
  const handleOpenPatchNotes = () => setNotesOpen(true)
  const handleClosePatchNotes = () => setNotesOpen(false)
  return (
    <>
      {/* <IconButton onClick={handleOpenPatchNotes}>
          <NewReleasesIcon />
        </IconButton> */}
      <IconButton
        onClick={handleOpenPatchNotes}
        sx={{
          display: 'flex',
          justifyContent: 'center', // Horizontal centering
          alignItems: 'center', // Vertical centering
          width: '40px', // Ensure width and height are explicitly set
          height: '40px',
          padding: 0, // Remove any internal padding
        }}
      >
        <Typography
          sx={{
            color: 'rgb(255, 148, 112)',
            fontSize: 14,
            textAlign: 'center', // Ensures text is centered within its container
          }}
        >
          {`v${version}`}
        </Typography>
      </IconButton>

      <Modal open={notesOpen} onClose={handleClosePatchNotes}>
        <BoxScroller
          sx={{
            position: 'absolute',
            maxHeight: '90vh',
            maxWidth: '50vh',
            top: '42%',
            left: '50%',
            transform: 'translate(-50%, -43%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            pl: 4,
            pr: 4,
            pb: 4,
            overflowY: 'auto',
            borderRadius: '25px 5px 25px 25px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'sticky',
              top: -2,
              zIndex: 100,
              backgroundColor: 'background.paper',
            }}
          >
            <Stack sx={{ m: 0, pt: 3 }}>
              <Typography variant='h4'>Patch Notes</Typography>
              <Typography variant='h6'>{`Version: v${version}`}</Typography>
            </Stack>
            <IconButton onClick={handleClosePatchNotes}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <List>
              {Object.entries(patchNotes).map(([key, { Features, 'Bug Fixes': BugFixes, Style, Refactors }]) => (
                <Box key={key} sx={{ mb: 1 }}>
                  <Typography variant='h6'>{key}</Typography>

                  {Features && Features.length > 0 && (
                    <>
                      <Typography variant='subtitle1' sx={{ color: 'rgba(3, 138, 255, 1)' }}>
                        Features
                      </Typography>
                      {Features.map((feature, index) => (
                        <ListItem key={index}>
                          <Typography variant='body2'>{feature.split(')')}</Typography>
                        </ListItem>
                      ))}
                    </>
                  )}

                  {Style && Style.length > 0 && (
                    <>
                      <Typography variant='subtitle1' sx={{ color: 'rgba(238, 152, 61, 0.8)' }}>
                        Style Changes
                      </Typography>
                      {Style.map((style, index) => (
                        <ListItem key={index}>
                          <Typography variant='body2'>{style.split(')')}</Typography>
                        </ListItem>
                      ))}
                    </>
                  )}
                  {BugFixes && BugFixes.length > 0 && (
                    <>
                      <Typography variant='subtitle1' sx={{ color: 'rgba(231, 109, 137, 1)' }}>
                        Bug Fixes
                      </Typography>
                      {BugFixes.map((fix, index) => (
                        <ListItem key={index}>
                          <Typography variant='body2'>{fix.split(')')}</Typography>
                        </ListItem>
                      ))}
                    </>
                  )}
                  {Refactors && Refactors.length > 0 && (
                    <>
                      <Typography variant='subtitle1' sx={{ color: 'rgba(3, 201, 169, 1)' }}>
                        Refactors
                      </Typography>
                      {Refactors.map((fix, index) => (
                        <ListItem key={index}>
                          <Typography variant='body2'>{fix.split(')')}</Typography>
                        </ListItem>
                      ))}
                    </>
                  )}
                </Box>
              ))}
            </List>
          </Box>
        </BoxScroller>
      </Modal>
    </>
  )
}

export default GroboticVersionControl
