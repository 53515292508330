import React, { useState } from 'react'
import {
  TextField,
  Box,
  InputAdornment,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
  Grid,
} from '@mui/material'
import ExistingDeal from '../CreateDeal/ExistingDealSelect'
import { calculateDayjsTotalMonths, calculateTotalMonths } from '../../utility/calculateTotalMonths'

const formatNumber = number => {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)
}

const feeFieldStyles = fieldBgColor => ({
  '& .MuiFormLabel-root': {
    fontWeight: 'bold',
    color: 'rgba(249, 180, 45, 1)',
    '&.Mui-focused': {
      color: 'rgba(255,255,255)',
      backgroundColor: fieldBgColor, // Ensures no background change on focus
    },
  },
  '& .MuiInputBase-root': {
    backgroundColor: fieldBgColor, // Ensures no background change on focus
  },
})
const readOnlyFieldStyles = fieldBgColor => ({
  '& .MuiInputBase-root': {
    pointerEvents: 'none', // Disables interaction
    border: 'none', // Removes border
    backgroundColor: fieldBgColor, // Ensures no background change on focus
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Makes the outline invisible
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Keeps the outline invisible on focus
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Keeps the outline invisible on hover
  },
  '& .MuiFormControl-root': {
    backgroundColor: fieldBgColor, // Ensures no background change on focus
  },
})

const ContractTotals = ({ newDeal, setNewDeal, totals, onChange, organization }) => {
  const [isSignatureRequired, setIsSignatureRequired] = useState(newDeal?.noSignature || false)
  const { totalMonths, allSame } = newDeal ? calculateDayjsTotalMonths(newDeal) : { totalsMonths: 1 }
  const theme = useTheme()
  const handleChange = event => {
    const { name, value } = event.target
    onChange(name, parseFloat(value))
  }
  const fieldBgColor = theme.palette.mode === 'dark' ? 'rgb(22, 28, 42, 0.7)' : 'rgba(232, 246, 252, 0.5)'

  const csaTypes = ['CSA', 'RCSA']
  const numberOfPaymentOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
  ]
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: '11px' }} gap={1}>
      <TextField
        label='Gross Profit Total'
        value={formatNumber(totals.grossProfit)}
        InputProps={{
          startAdornment: <InputAdornment position='start'>$</InputAdornment>,
        }}
        sx={readOnlyFieldStyles(fieldBgColor)}
      />
      <TextField
        label='Implementation Fee'
        name='implementationFee'
        type='number'
        value={totals.implementationFee || 0}
        onChange={handleChange}
        InputProps={{
          startAdornment: <InputAdornment position='start'>$</InputAdornment>,
        }}
        sx={feeFieldStyles(fieldBgColor)}
      />

      {allSame ? (
        <TextField
          label='Monthly Total'
          value={!csaTypes.includes(newDeal.type) ? formatNumber(totals.monthlyTotal) : '—'}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
          sx={readOnlyFieldStyles(fieldBgColor)}
          disabled={!csaTypes.includes(newDeal.type)}
        />
      ) : (
        <TextField label='Monthly Total' value={'Varying dates'} sx={readOnlyFieldStyles(fieldBgColor)} disabled />
      )}

      <TextField
        label='Total'
        value={formatNumber(totals.total)}
        InputProps={{
          startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          endAdornment:
            newDeal && newDeal.type === 'IO' ? (
              <InputAdornment position='end'>
                <Typography sx={{ fontSize: 13, color: 'rgb(134, 137, 145)' }}>{`${totalMonths} mo(s).`}</Typography>
              </InputAdornment>
            ) : null,
        }}
        sx={readOnlyFieldStyles(fieldBgColor)}
      />

      {newDeal && newDeal.type && csaTypes.includes(newDeal.type) && (
        <TextField
          label='Payments'
          name='numberOfPayments'
          InputProps={{
            startAdornment: <InputAdornment position='start'>#</InputAdornment>,
          }}
          select
          value={newDeal && newDeal.numberOfPayments ? newDeal.numberOfPayments : 2}
          onChange={handleChange}
          sx={{ minWidth: '15%', maxWidth: '15%' }}
        >
          {numberOfPaymentOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      {/* <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}> */}
      <ExistingDeal
        noSignature={isSignatureRequired}
        setNoSignature={setIsSignatureRequired}
        newDeal={newDeal}
        setNewDeal={setNewDeal}
        organization={organization}
      />
      {/* </Box> */}
    </Box>
  )
}

export default ContractTotals
