const productNames = [
  'Standard Conversion Tracking',
  'Ecommerce Conversion Tracking',
  'Advanced Conversion Tracking/Reporting',
  'Competitor Intelligence Report',
]

export const determineStrategyFields = productName => {
  let strategyState = {
    paidSearch: false,
    paidSocial: false,
    seo: false,
    smm: false,
    website: false,
    display: false,
    creative: false,
  }
  switch (productName) {
    case 'Meta Advertising':
    case 'Linkedin Advertising':
    case 'Spotify Advertising':
    case 'TikTok Advertising':
    case 'SnapChat Advertising':
    case 'Social Media Advertising Management Fee':
    case 'Linkedin Advertising':
    case 'Paid Social Management Fee':
      strategyState.paidSocial = true
      break
    case 'Paid Search Advertising':
    case 'Paid Search Management Fee':
    case 'Paid Search - Media Spend':
    case 'Paid Search Advertising':
    case 'Microsoft Advertising':
    case 'Amazon Advertising':
      strategyState.paidSearch = true
      break
    case 'Agency Retainer - Digital':
    case 'Management Fee / Retainer':
      strategyState.paidSearch = true
      strategyState.paidSocial = true
      strategyState.display = true
      break
    case 'Website Hosting':
    case 'Website Build - Page Creation':
    case 'Website Hosting + 30 Minutes of Maintenance':
    case 'Website - Add On':
    case 'Website Build - Additional Page Creation':
    case 'Website Revisions - Additional':
    case 'Standard Website Build':
    case 'Website Revisions':
    case 'Website Hosting':
    case 'Premium Website Build':
    case 'Monthly Website Plan':
    case 'Website Maintenance Retainer':
    case 'Website Transfer Fee':
    case 'Website Hosting + 60 Minutes of Maintenance':
    case 'Agency Retainer - Website':
    case 'E-Commerce Website Build':
    case 'Monthly Hosting Plan':
      strategyState.website = true
      break
    case 'Programmatic Display Advertising':
    case 'Programmatic Display Management Fee':
    case 'Programmatic Video Advertising':
    case 'Programmatic Display Advertising':
    case 'Programmatic Audio Advertising':
    case 'Google Display Advertising':
    case 'Display Management Fee':
    case 'Geofencing Advertising Management Fee':
    case 'Youtube Advertising':
    case 'Retargeting Advertising Management Fee':
    case 'Audio Management Fee':
      strategyState.display = true
      break
    case 'Design Materials':
    case 'Graphic Design - Logo Creation':
    case 'Graphic Design - Additional Logo Revisions':
    case 'Graphic Design - Revisions':
    case 'Graphic Design - General':
    case 'Photo Editing':
    case 'Photo Shoot':
    case 'Drone Video - Add On':
    case 'Lifestyle Video Shoot':
    case 'Video Editing':
    case 'Video Management Fee':
    case 'Video Shoot':
    case 'Agency Retainer - Creative':
    case 'Business Systems Kit':
    case 'Branding Guide':
    case 'Talent/Models':
    case 'Property Naming':
    case 'Custom API Integration':
    case 'Marketing Collateral':
    case 'Content Migration':
    case 'Creative Services - Hourly':
    case 'Product Photography Staging Equipment':
    case 'Full Brand Build':
    case 'Drone Shoot - Add On':
    case 'E-Commerce Integration':
    case 'Travel, Lodging, Misc.':
    case 'Landing Page':
    case 'Server Migration':
      strategyState.creative = true
      break
    case 'SEO - Custom Tier':
    case 'SEO - Tier 1':
    case 'SEO - Tier 2':
    case 'Monthly SEO Plan - Tier 1':
    case 'SEO - Additional Location':
    case 'SEO - Tier 4':
    case 'SEO - Tier 3':
    case 'Listing Sync Pro':
      strategyState.seo = true
      break
    case 'Social Media Management':
    case 'Social Content Calendar':
    case 'Social Profile Creation':
      strategyState.smm = true
      break
    case 'Consulting':
      strategyState.seo = true
      strategyState.smm = true
      strategyState.creative = true
      strategyState.website = true
      strategyState.paidSearch = true
      strategyState.paidSocial = true
      strategyState.display = true
      break
    default:
      break
  }

  return strategyState
}
export const determineStrategyState = products => {
  return products && Array.isArray(products)
    ? products.reduce(
        (acc, product) => {
          const productStrategy = determineStrategyFields(product.productName)
          return {
            paidSearch: acc.paidSearch || productStrategy.paidSearch,
            paidSocial: acc.paidSocial || productStrategy.paidSocial,
            seo: acc.seo || productStrategy.seo,
            smm: acc.smm || productStrategy.smm,
            website: acc.website || productStrategy.website,
            display: acc.display || productStrategy.display,
            creative: acc.creative || productStrategy.creative, // Include 'creative'
          }
        },
        {
          paidSearch: false,
          paidSocial: false,
          seo: false,
          smm: false,
          website: false,
          display: false,
          creative: false, // Include default 'creative'
        }
      )
    : {
        paidSearch: false,
        paidSocial: false,
        seo: false,
        smm: false,
        website: false,
        display: false,
        creative: false, // Default 'creative' if no products
      }
}
