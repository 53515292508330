import { styled } from '@mui/material'
import { Box, Button, Typography, TextField } from '@mui/material'
import { Link } from 'react-router-dom'

export const DialogIcon = styled('img')({
  width: 50,
  height: 50,
  borderRadius: '50%',
})
export const gridStyles = theme => ({
  width: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? '' : 'rgb(232, 246, 252)',
  color: '#fff',
  minHeight: '50vh',
  maxHeight: '95vh',
  padding: '20px',
  '& .MuiDialogContent-root': {
    width: '100%',
    padding: '20px',
  },
  '& .MuiTextField-root': {
    margin: '10px 0',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        // This targets the border of the TextField
        border: 'none', // Removes the border
      },
      '&:hover fieldset': {
        border: 'none', // Ensures border is removed on hover
      },
      '&.Mui-focused fieldset': {
        border: 'none', // Removes the border when the TextField is focused
      },
      '&.Mui-focused fieldset': {
        border: '2px solid rgb(159, 176, 219)', // Changes border when focused
        // backgroundColor: '#e3f2fd', // Changes background when focused
      },
    },
  },
  '& .MuiInputBase-root': {
    height: '45px',
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(22, 28, 42)' : 'rgba(255,255,255,0.6)',
  },
  '& input[type="number"]': {
    // Hides the up and down arrows on webkit browsers like Chrome, Safari, and Edge
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    // Hides the up and down arrows on Firefox
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
  },
})
export const dialogStyles = theme => ({
  width: '100%',
  maxWidth: '800px',
  backgroundColor: theme.palette.mode === 'dark' ? '#050607' : 'rgba(255, 255, 255)',
  color: theme.palette.mode === 'dark' ? '#fff' : 'black',
  minHeight: '50vh',
  padding: '20px',
  '& .MuiDialogContent-root': {
    width: '100%',
    padding: '20px',
  },
  '& .MuiTextField-root': {
    margin: '10px 0',
  },
})

export const personIconStyles = {
  color: 'rgb(255, 123, 187)',
}

export const dialogTitleStyles = {
  fontWeight: 'bold',
  textAlign: 'center',
}
export const boxStyles = theme => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  width: '100%',
  border: '1px solid',
  borderColor: 'transparent',
  padding: theme.spacing(1),
})
export const dialogContentStyles = {
  width: 'auto',
  maxWidth: '700px',
  margin: '0 auto',
}

export const inputStyles = theme => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgb(229, 229, 229)',
  borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
})
export const ContactTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30 43 59)' : '#ffffff',
  textAlign: 'left',
  borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0,0,0,0.12)', // Dim white borde
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',

  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.32)' : 'rgba(0,0,0,0.22)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.12)', // Dim white border
    },
    '&:hover fieldset': {
      borderWidth: '0px !important',
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px !important',
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
      // backgroundColor: theme.palette.mode === 'dark' ? 'red' : '#f0f0f0', // Change background color when focused
      // borderColor: 'white', // Change border color when focused
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    // backgroundColor: theme.palette.mode === 'dark' ? 'red' : '#f0f0f0', // Change background color when focused
  },
  '& .MuiOutlinedInput-input': {
    '&:focus': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgb(40 53 69)' : '#fffff', // Ensure the input itself also changes background color
    },
  },
}))
export const InfoBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : '#f4f6f8',
  padding: theme.spacing(6),
  borderRadius: '25px',
}))

export const InfoRow = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  position: 'relative',
}))

export const InfoLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 500,
  // fontSize: '0.875rem',
  fontSize: '1.175rem',
  marginBottom: theme.spacing(0.5),
}))

export const InfoValueWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingBottom: theme.spacing(1),
}))

export const InfoValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
  fontSize: '1rem',
}))

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#90caf9' : '#1976d2',
  fontWeight: 700,
  fontSize: '1rem',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export const Underline = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '1px',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)',
}))

export const CityStateZipRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  position: 'relative',
}))
export const gridContainerStyles = {
  display: 'flex',
  maxHeight: '100vh',
  // width: '100%',
  justifyContent: 'center',
  // alignItems: 'center',
}

export const deleteButtonStyles = theme => ({
  borderColor: 'black',
  color: 'red',
  fontWeight: 'bold',
  ':hover': {
    backgroundColor: 'rgba(201, 50, 46)',
    borderColor: 'black',
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
})
export const textStyles = theme => ({
  width: '100%',
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  padding: theme.spacing(1),
  fontSize: 18,
})

export const buttonContainerStyles = theme => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: theme.spacing(2),
  gap: theme.spacing(1),
})
export const popoverStyles = theme => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(30, 39, 53, 0.85)' : 'rgba(255, 255, 255, 0.85)',
  zIndex: 1000,
})

export const boxContainerStyles = theme => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: theme.spacing(2),
})
export const detailHeaderStyle = theme => ({
  fontWeight: 'bold',
  fontSize: 16,
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
})
export const headerStyle = theme => ({
  fontWeight: 'bold',
  fontSize: 24,
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
})
export const HeaderButton = styled(Button)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? 'rgb(51, 67, 91)' : 'rgb(211, 211, 211)',
  backgroundColor: 'rgb(29, 106, 82)',
  color: theme.palette.mode === 'dark' ? 'white' : 'white',
}))
export const stringAvatar = (name, fontsize) => {
  const nameParts = name.split(' ')
  if (nameParts.length < 2) {
    return {
      children: (
        <Typography
          sx={{
            fontSize: fontsize ? fontsize : 14,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {nameParts[0][0]}
        </Typography>
      ),
    }
  }

  return {
    children: (
      <Typography
        sx={{
          fontSize: fontsize ? fontsize : 14,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        {`${nameParts[0][0]}${nameParts[1][0]}`}
      </Typography>
    ),
  }
}
export const listItemStyle = (theme, fontsize) => ({
  fontSize: fontsize ? fontsize : 15,
  // bgcolor: 'transparent',
  p: 0,
})
export const filterPanelProps = {
  '& .MuiDataGrid-filterForm': {
    width: '100%',
    // padding: '10px',
    borderRadius: '8px',
    display: 'flex', // Make the form a flex container
    alignItems: 'center', // Vertically align items in the center
    gap: '16px', // Add space between each form element
    '& .MuiFormControl-root': {
      flex: '1 1 auto',
      margin: '0',
      minWidth: '100px',
      '& .MuiInputBase-root': {
        padding: '4px', // Adjust padding for input fields
        borderRadius: '4px',
      },
    },
  },
  '& .MuiDataGrid-filterFormValueInput': {
    flex: '2 1 auto', // Allow the value input more space
    minWidth: '150px', // Minimum width for input
    '& input': {
      padding: '6px 8px', // Adjust padding for the input field
      width: '100%',
    },
  },
  '& .MuiSvgIcon-root': {
    fontSize: '18px', // Decrease the size of the 'x' icon
  },
}
