import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import duration from 'dayjs/plugin/duration'
import minMax from 'dayjs/plugin/minMax'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(duration)
dayjs.extend(minMax)

export const calculatePaymentSchedule = deal => {
  const paymentSchedule = []
  const { products } = deal

  // Filter out any products with invalid dates
  const validProducts = products.filter(product => product.startDate && product.endDate)

  if (validProducts.length === 0) {
    return [] // Return an empty schedule if no valid products exist
  }

  // Determine the earliest start date and latest end date among all valid products
  const start = dayjs.min(validProducts.map(p => dayjs(p.startDate)))
  const end = dayjs.max(validProducts.map(p => dayjs(p.endDate)))

  // Iterate over each month between the start and end date
  for (let current = start.startOf('month'); current.isSameOrBefore(end); current = current.add(1, 'month')) {
    const month = current.format('MM')
    const year = current.format('YYYY')
    let paymentAmount = 0
    let productCount = 0
    let activeDays = 0
    let productsAssociated = []

    validProducts.forEach(product => {
      const productStart = dayjs(product.startDate)
      const productEnd = dayjs(product.endDate)

      // Check if the product is active in the current month
      if (productStart.isSameOrBefore(current.endOf('month')) && productEnd.isSameOrAfter(current.startOf('month'))) {
        productCount += 1
        productsAssociated.push(product.productName)
        // Calculate the overlap days
        const overlapStart = productStart.isBefore(current.startOf('month')) ? current.startOf('month') : productStart
        const overlapEnd = productEnd.isAfter(current.endOf('month')) ? current.endOf('month') : productEnd
        activeDays += overlapEnd.diff(overlapStart, 'days') + 1
        // Assuming the price is the monthly amount, adjust if necessary
        paymentAmount += parseFloat(product.price)
      }
    })

    paymentSchedule.push({
      month,
      year,
      paymentAmount: paymentAmount.toFixed(2),
      productCount,
      activeDays,
      productsAssociated: productsAssociated.join(', '),
    })
  }

  return paymentSchedule
}
