import * as React from 'react'
import { useEffect } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import GroboticPngIconDark from '../..//images/Grobotic png-lightmode.png'
import GroboticPngIconLight from '../../images/Grobotic png.png'
import GroboticLogoLight from '../../images/GRObotic Logo-full-lightmode.png'
import GroboticLogoDark from '../../images/GRObotic Logo-darkfull.png'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import MainNavigationSection from './MainNavigation/MainNavigationSection'
import DevNavigationSection from './DevNavigation/DevNavigationSection'
import BoardsNavigation from './BoardsNavigation/BoardsNavigation'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { NotificationProvider } from '../../context/NotificationProvider/NotificationProvider'
import MenuItemsNavSection from './MenuItemsNavSection/MenuItemsNavSection'
const drawerWidth = 260

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white',
})

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export default function MiniDrawer({
  handleOpenCreateOrganization,
  handleOpenCreateDeal,
  handleOpenCreateContact,
  isNotifyDrawerOpen,
  onOpenNotify,
  onCloseNotify,
  children,
}) {
  const theme = useTheme()
  const [testLoading, setTestLoading] = React.useState(true)
  const [open, setOpen] = React.useState(false)
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const { user } = activeUser ? activeUser : {}
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedPath, setSelectedPath] = React.useState(location.pathname)
  useEffect(() => {
    console.log(`Route Endpoint: ${location.pathname}`)
    setSelectedPath(location.pathname)
  }, [location])
  const drawerBgColor = theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'white'

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }
  const handleNavigation = path => {
    console.log('PATH: ', path)
    setSelectedPath(location.pathname)
    navigate(`/${path}`)
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <CssBaseline />
      {/* <AppBar sx={{ borderRadius: 0 }} position='fixed' open={open}> */}

      <Box sx={{ zIndex: 800, position: 'sticky' }} />
      {/* </AppBar> */}
      <Drawer
        variant='permanent'
        open={open}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: drawerBgColor, // apply theme-dependent style here
            width: drawerWidth,
            boxSizing: 'border-box',
            ...(open && {
              width: drawerWidth,
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }),
            ...(!open && {
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              overflowX: 'hidden',
              width: `calc(${theme.spacing(7)} + 1px)`,
              [theme.breakpoints.up('sm')]: {
                width: `calc(${theme.spacing(8)} + 1px)`,
              },
            }),
          },
        }}
      >
        <DrawerHeader>
          {open ? (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {theme.palette.mode === 'dark' ? (
                  <img style={{ width: '180px', height: 'auto', padding: 0, margin: 0 }} src={GroboticLogoDark} />
                ) : (
                  <img style={{ width: '180px', height: 'auto', padding: 0, margin: 0 }} src={GroboticLogoLight} />
                )}
              </Box>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                edge='start'
                sx={{
                  padding: 0.2, // Remove padding from the button
                  // marginRight: '1px', // Adjust margin based on the state of the drawer
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 900,
                  ...(open && { display: 'none' }),
                }}
              >
                <img
                  src={theme.palette.mode === 'dark' ? GroboticPngIconLight : GroboticPngIconDark}
                  style={{ width: '40px', height: 'auto', padding: 0, margin: 0 }}
                />
              </IconButton>
            </Box>
          )}
        </DrawerHeader>

        <Divider />
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '90vh' }}>
          <Box>
            <MainNavigationSection handleNavigation={handleNavigation} open={open} selectedPath={selectedPath} />
            <Divider />
            <BoardsNavigation handleNavigation={handleNavigation} open={open} selectedPath={selectedPath} />
            <Divider />
            {user && user.id === 'U062JDP8R5X' && (
              <>
                <DevNavigationSection handleNavigation={handleNavigation} open={open} selectedPath={selectedPath} />
                <Divider />
              </>
            )}
          </Box>
          <Box>
            <Divider />
            <NotificationProvider>
              <MenuItemsNavSection
                open={open}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                imageUrl={activeUser?.user?.profile?.image_512 || null}
                handleOpenCreateOrganization={handleOpenCreateOrganization}
                handleOpenCreateDeal={handleOpenCreateDeal}
                handleOpenCreateContact={handleOpenCreateContact}
              />
            </NotificationProvider>
          </Box>
        </Box>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  )
}
