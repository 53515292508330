import { Table, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'

export const EmptyRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(22, 28, 42, 0.3)' : 'rgba(255,255,255,1)',
  border: '1px dotted', // Use a dotted border
  minHeight: '81px',
  width: '100%',
  height: '100%', // Full height
  display: 'flex', // Enable flexbox
  justifyContent: 'center', // Center horizontally
  alignItems: 'center', // Center vertically
}))

export const EmptyTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(22, 28, 42, 0.1)' : 'rgba(255,255,255,1)',
  border: '1px',
  borderStyle: 'dotted',
  minHeight: '81px',
  height: '81px',
}))
