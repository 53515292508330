import React from 'react'
import { Box, Avatar, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
const RenderOrganizationColumn = ({ params, stringAvatar, orgId, photoUrl, densityStyles }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        height: '100%',
        padding: densityStyles.padding,
        gap: densityStyles.gap,
      }}
    >
      <Link to={`/organizations/${orgId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'left',
            height: '100%',
            width: '100%',
            fontWeight: 'bold',
          }}
        >
          {photoUrl ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                alignItems: 'center',
                height: '100%',
                gap: 1,
              }}
            >
              <Avatar
                src={photoUrl}
                sx={{
                  width: densityStyles.avatarSize,
                  height: densityStyles.avatarSize,
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
                alt={params && params.value ? params.value : params}
              />

              <Typography
                sx={{
                  fontSize: densityStyles.fontSize,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                {params && params.value ? params.value : params}
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                alignItems: 'center',
                height: '100%',
                gap: 1,
              }}
            >
              <Avatar
                {...stringAvatar(params && params.value ? params.value : params)}
                sx={{
                  backgroundColor: 'rgb(114, 229, 218)',
                  width: densityStyles.avatarSize,
                  height: densityStyles.avatarSize,
                }}
                alt={params && params.value ? params.value : params}
              />
              <Typography
                sx={{
                  fontSize: densityStyles.fontSize,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                {params && params.value ? params.value : params}
              </Typography>
            </Box>
          )}
        </Box>
      </Link>
    </Box>
  )
}

export default RenderOrganizationColumn
