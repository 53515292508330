import React, { useEffect, useState } from 'react'
import { Grid, Box, Button, useTheme, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useUpdateItem } from '../../api/aws/useUpdateItem'
import ViewStrategyForm from './ViewStrategyForm'
import { useDeal } from '../../api/aws/useDeal'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { editFieldConfig } from './editFieldConfig'
import SelectStrategyTemplate from './SelectStrategyTemplate'
import { determineStrategyState } from './determineStrategyfields'
import { templates } from './templates'
import { BoxScroller } from '../../style/styleElements'
import RenderStrategySection from './RenderStrategySection'
import RenderStrategyHeader from './RenderStrategyHeader'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'

const Strategy = () => {
  const { dealId } = useParams()
  // const { deal } = useDeal(dealId)
  const { newDeal: deal } = useDealDetailsContext()
  const orgId = deal && Array.isArray(deal.organizations) ? deal.organizations[0] : []
  const [strategyState, setStrategyState] = useState({
    paidSearch: false,
    paidSocial: false,
    seo: false,
    smm: false,
    website: false,
    display: false,
    creative: false,
  })
  const { organization } = useOrganizationById(orgId)
  const { showSnackbar } = useSnackbarContext()
  const { mutate: updateItem } = useUpdateItem()
  const [formState, setFormState] = useState({})
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const [isEditStrategyMode, setIsEditStrategyMode] = useState(false)
  const [initialFormState, setInitialFormState] = useState(null)
  const [campaigns, setCampaigns] = useState([])
  const [ads, setAds] = useState([])
  const theme = useTheme()

  useEffect(() => {
    if (deal) {
      const initialStrategyState = {
        ...deal,
        organizationName: organization ? organization.name : '',
        type: deal ? deal.type : '',
        website: organization ? organization.website : '',
        street: organization ? organization.street : '',
        city: organization ? organization.city : '',
        state: organization ? organization.state : '',
        zip: organization ? organization.zip : '',
        vertical: organization && Array.isArray(organization.vertical) ? organization.vertical[0] : '',
        cmsUsed: organization ? organization.cmsUsed : '',
      }
      setFormState(initialStrategyState)
      setInitialFormState(initialStrategyState)
    }
  }, [deal, organization])
  useEffect(() => {
    if (deal && Array.isArray(deal.products) && deal.products.length > 0) {
      const computedStrategyState = determineStrategyState(deal.products)
      setStrategyState(computedStrategyState)
    } else {
      // Fallback to default empty strategy state if no products
      setStrategyState({
        paidSearch: false,
        paidSocial: false,
        seo: false,
        smm: false,
        website: false,
        display: false,
        creative: false,
      })
    }
  }, [deal])
  useEffect(() => {
    if (strategyState) {
      console.log('strategyState: ', strategyState)
    }
  }, [strategyState])
  const handleTemplateSelect = event => {
    const selectedTemplate = templates[event.target.value]
    setSelectedTemplate(event.target.value)

    const mappedTemplate = {}

    Object.keys(editFieldConfig).forEach(section => {
      editFieldConfig[section].forEach(field => {
        if (selectedTemplate[field.name] !== undefined) {
          mappedTemplate[field.name] = selectedTemplate[field.name]
        }
      })
    })

    setFormState(prevState => ({
      ...prevState,
      ...mappedTemplate,
    }))

    if (selectedTemplate.campaigns) {
      setCampaigns(selectedTemplate.campaigns)
    }

    if (selectedTemplate.ads) {
      setAds(selectedTemplate.ads)
    }
  }

  const handleChange = event => {
    const { name, value } = event.target
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleBlur = event => {
    const { name, value } = event.target

    // Use empty string as the default value if the property doesn't exist
    const initialValue = initialFormState[name] ?? ''

    // Ensure the field's value has changed and is not both empty and initially empty
    if (initialValue !== value && !(initialValue === '' && value === '')) {
      const updateData = {
        primaryKey: 'deal_uuid',
        tableName: 'deals',
        itemId: dealId,
        fieldName: name,
        newValue: value,
      }

      updateItem(updateData, {
        onSuccess: () => {
          showSnackbar(`${name} updated successfully`, 'success')
        },
        onError: err => {
          console.error('Mutation Error:', err)
        },
      })
    }
  }

  return (
    <>
      {/* Sticky Header for General Info and Integration */}
      <Box
        sx={{
          position: 'sticky',
          top: -21,
          zIndex: 900,
          width: '100%',
          bgcolor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgb(232, 246, 252)',
          border: '1px solid',
        }}
      >
        <RenderStrategyHeader
          isEditMode={isEditStrategyMode}
          setIsEditMode={setIsEditStrategyMode}
          formState={formState}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Box>

      {/* Scrollable Area for the Rest of the Content */}
      {/* <BoxScroller
        sx={{
          maxHeight: 'calc(100vh - 150px)', // Adjust to leave room for the sticky header
          overflowY: 'auto',
          width: '100%',
          // bgcolor: 'background.paper',
        }}
      > */}
      <Grid container sx={{ width: '100%' }} mt={2}>
        <Box sx={{ width: '100%' }}>
          {isEditStrategyMode ? (
            <>
              <SelectStrategyTemplate
                templates={templates}
                selectedTemplate={selectedTemplate}
                onChange={handleTemplateSelect}
              />
              {formState && (
                <ViewStrategyForm
                  formState={formState}
                  isEditMode={isEditStrategyMode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  campaigns={campaigns}
                  ads={ads}
                  strategyState={strategyState}
                />
              )}
            </>
          ) : (
            <>
              {formState && (
                <ViewStrategyForm
                  formState={formState}
                  isEditMode={isEditStrategyMode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  campaigns={campaigns}
                  ads={ads}
                  strategyState={strategyState}
                />
              )}
            </>
          )}
        </Box>
      </Grid>
      {/* </BoxScroller> */}
    </>
  )
}

export default Strategy
