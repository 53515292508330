import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDeal } from '../../api/aws/useDeal'
import { Grid, useTheme, useMediaQuery } from '@mui/material'
import GenerateContractTable from './GenerateContractTable'
import {
  contractMap,
  generalInfoMap,
  payInfoMap,
  totalsMap,
  ioTotalsMap,
  ioContractMap,
  csaTotalsMap,
} from './dataMaps'
import { useOrganizations } from '../../api/aws/useOrganizations'
import DetailsLoading from '../Loading/DetailsLoading'
import GenerateTable from './GenerateTable'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { calculateTotalMonthsForProducts } from '../../utility/calculateTotalMonths'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import useTotals from '../../api/customHooks/useTotals'

const ReviewDeal = () => {
  const { dealId } = useParams()
  // const { deal, isDealLoading, isDealError } = useDeal(dealId)
  const { newDeal, productRows } = useDealDetailsContext()
  const [productList, setProductList] = useState(newDeal?.products || [])
  const [selectedOrganization, setSelectedOrganization] = useState()
  const totals = useTotals(productRows, newDeal?.implementationFee || 0, newDeal?.type)
  console.log('[Review Deal] totals: ', totals)
  const orgId = newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : []
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (newDeal && organization) {
      console.log('DEAL: ', newDeal)
      setSelectedOrganization(organization)
    }
  }, [newDeal, organization])

  if (isOrganizationLoading) {
    return <DetailsLoading />
  }
  return (
    <>
      <Grid container spacing={2}>
        {/* First Row with General Info and Totals Info */}
        <Grid item xs={12} sm={6}>
          <GenerateContractTable options={generalInfoMap(organization)} section={'General Info'} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <GenerateContractTable
            options={
              newDeal && newDeal.type === 'IO'
                ? ioTotalsMap(newDeal, totals)
                : ['CSA', 'RCSA'].includes(newDeal.type)
                ? csaTotalsMap(newDeal, totals)
                : totalsMap(totals)
            }
            section={'Totals Info'}
          />
        </Grid>
        <Grid item xs={12}>
          <GenerateTable
            options={newDeal && newDeal.type === 'IO' ? ioContractMap(productList) : contractMap(productList)}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ReviewDeal
