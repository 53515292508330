import React, { useState, useEffect } from 'react'
import {
  List,
  ListItem,
  Tooltip,
  Typography,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Switch,
  useTheme,
} from '@mui/material'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize'
import GridViewIcon from '@mui/icons-material/GridView'
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects'
import GroIconDark from '../../../images/gro logo dark font.png'
import GroMarketingIcon from '../../Icons/GroMarketingIcon'
import CreateMenuIcons from '../CreateMenuIcons'
import CreateMenu from '../../CreateMenu/CreateMenu'
import ThemeToggleButton from '../../../themeProviders/ThemeToggleButton/ThemeToggleButton'
import AddIcon from '@mui/icons-material/Add'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import UserImage from '../../AvatarImageComponents/UserImage'
import ShowNotifications from '../../NotificationDrawer/ShowNotifications'
import GroboticVersionControl from '../../Version'

const InfoMessage = ({ imageUrl }) => {
  return (
    <List>
      <ListItem>
        <AddIcon />
        <Typography sx={{ fontSize: '12px' }}>Menu for creating</Typography>
      </ListItem>
      <ListItem>
        <NotificationsNoneIcon />
        <Typography sx={{ fontSize: '12px' }}>Click to view notifications</Typography>
      </ListItem>
      <ListItem>
        <Switch disabled />
        <Typography sx={{ fontSize: '12px' }}>Toggle light and dark mode</Typography>
      </ListItem>
      <ListItem>
        <UserImage imageUrl={imageUrl} />
        <Typography sx={{ fontSize: '12px' }}>Current user image from Slack</Typography>
      </ListItem>
    </List>
  )
}

const MenuItemsNavSection = ({
  open,
  drawerOpen,
  setDrawerOpen,
  onNotifyClose,
  imageUrl,
  handleOpenCreateOrganization,
  handleOpenCreateDeal,
  handleOpenCreateContact,
}) => {
  const theme = useTheme()
  return (
    <List>
      {[
        { label: 'Create Menu' },
        { label: 'Toggle Theme' },
        { label: 'Notifications' },
        { label: 'Version' },
        // { label: 'Team Tasks', path: 'teamtasks' },
        // { label: 'New Client Onboards', path: 'newclientonboards' },
        // { label: 'Creative Requests', path: 'creativerequests' },
        // Add more items here if necessary
      ].map((item, index) => (
        <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {/* You can decide what icon to use based on index or some other logic */}
              {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
              {index === 0 && (
                <CreateMenu
                  onOpenCreateOrganization={handleOpenCreateOrganization}
                  onOpenCreateDeal={handleOpenCreateDeal}
                  onOpenCreateContact={handleOpenCreateContact}
                />
              )}
              {index === 1 && <ThemeToggleButton />}
              {index === 2 && (
                <ShowNotifications drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} imageUrl={imageUrl} />
              )}
              {index === 3 && <GroboticVersionControl />}
            </ListItemIcon>
            <ListItemText
              sx={{
                opacity: open ? 1 : 0,
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'black',
                }}
              >
                {item.label}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

export default MenuItemsNavSection
