export const templates = {
  'Search Platform - Google': {
    platform: 'Google',
    demographicTargeting: 'Typical MF',
    landingPageUrl: 'See spreadsheet',
    targetingSpecifics: '',
    campaigns: [
      {
        name: 'Campaign One: Property Name - Search - Branded',
        location: 'City, 5-10 miles around address',
        adGroup: 'Property Name - Branded',
        keywords: [
          '"property name"',
          '[property name]',
          '"property name city"',
          '[property name city]',
          '"property name apartments"',
          '[property name apartments]',
        ],
        negativeKeywords: [],
      },
      {
        name: 'Campaign Two: Property Name - Search - City',
        location: 'City, 5-10 miles around address',
        adGroup: 'City',
        keywords: [
          '"**city** apartments"',
          '"**city** *state* apartments"',
          '"**city** apartments for rent"',
          '"Apartments near **city**"',
          '"Apartments for rent **city**"',
          '"Apartments in **city** *state*"',
        ],
        negativeKeywords: ['property name'],
      },
      {
        name: 'Campaign Three: Property Name - Search - Near Me',
        location: 'City, 5-10 miles around address',
        adGroup: 'Near Me',
        keywords: [
          '“Studio apartments near me”',
          '“Apartments near me”',
          '“Apartments for rent near me”',
          '“One bedroom apartments near me”',
          '“Apartment complexes near me”',
          '“Apartment communities near me”',
          '“1 bedroom apartments near me”',
          '“2 bedroom apartments near me”',
          '“3 bedroom apartments near me”',
        ],
        negativeKeywords: [],
      },
      {
        name: 'Campaign Two: Property Name - Search - City',
        location: 'City, 5-10 miles around address',
        adGroup: 'City',
        keywords: [
          '"**city** apartments"',
          '"**city** *state* apartments"',
          '"**city** apartments for rent"',
          '"Apartments near **city**"',
          '"Apartments for rent **city**"',
          '"Apartments in **city** *state*"',
        ],
        negativeKeywords: [],
      },
    ],
  },
  'Social Targeting - FB, IG': {
    platform: 'Facebook, Instagram',
    demographicTargeting: 'Typical MF',
    landingPageUrl: 'See spreadsheet',
    targetingSpecifics: '',
    geoTargeting: '15 miles around city - radius size can be increased',
    campaigns: [
      {
        name: 'Campaign One: Property Name - Leads',
        location: 'City, 5-10 miles around address',
        adGroup: '', // Add missing adGroup
        keywords: [], // Add empty keywords
        negativeKeywords: [], // Add empty negative keywords
      },
    ],
    ads: [
      {
        'Set 1': {
          name: 'Leads - Universal Conversion - Interest Targeting',
          conversionLocation: 'Website',
          performanceGoal: 'Maximize number of conversions',
          conversionEvent: 'Universal Conversion',
          audience: [
            'Renting (real estate)',
            'Rent.com (real estate)',
            'Penthouse apartment (real estate)',
            'Apartments.com (real estate)',
            'Apartment List (real estate)',
            'Apartment Guide (real estate)',
            'Apartment (real estate)',
            'Zillow (real estate)',
            'Trulia (real estate)',
            'Property finder (real estate)',
            'Real estate (industry)',
            'Residential area (real estate)',
            'Premier Agent - Zillow & Trulia (real estate)',
          ],
        },
        'Set 2': {
          name: 'Open Targeting',
          placements: 'Advantage+ Placements',
        },
        exclusions: ['FormFill', 'Account Creation'],
      },
    ],
  },
}
